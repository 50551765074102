import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'

import { i18n } from '@/i18n'
import Decimal from 'decimal.js'
import type { Impact, ImpactType } from '@/api/interfaces'
import { IMPACT_TYPES } from '@/api/interfaces'

export function formatNumberByLocale(value: number) {
  const locale = i18n.locale.value || 'en-EN'
  return new Intl.NumberFormat(locale).format(value)
}

export function formatTotalAmounts(value: number, suffix?: string): string {
  const locale = i18n.locale.value || 'en-EN'

  if (value < 100) {
    const amount = new Decimal(value).toDecimalPlaces(3, Decimal.ROUND_FLOOR).toNumber()
    return new Intl.NumberFormat(locale).format(amount) + ' ' + (suffix || '')
  } else if (value < 10 ** 6) {
    const amount = new Decimal(value).toDecimalPlaces(0).toNumber()
    return new Intl.NumberFormat(locale).format(amount) + ' ' + (suffix || '')
  } else if (value < 10 ** 9) {
    const amount = new Decimal(value)
      .round()
      .dividedBy(10 ** 6)
      .toDecimalPlaces(3)
      .toNumber()
    return (
      new Intl.NumberFormat(locale).format(amount) + ' ' + i18n.t('ComparisonCardPublic.million')
    )
  } else {
    const amount = new Decimal(value)
      .round()
      .dividedBy(10 ** 9)
      .toDecimalPlaces(3)
      .toNumber()
    return (
      new Intl.NumberFormat(locale).format(amount) + ' ' + i18n.t('ComparisonCardPublic.billion')
    )
  }
}

export function snakeCase(string: string) {
  return string
    .split('')
    .map((letter, idx) => {
      if (letter === ' ') {
        return '_'
      } else if (isNaN(parseFloat(letter)) && letter.toUpperCase() === letter) {
        return `${idx !== 0 ? '_' : ''}${letter.toLowerCase()}`
      } else {
        return letter
      }
    })
    .join('')
}

export const kebabCaseToCamelCase = (string: string): string => {
  return string.replace(/-([a-z])/g, (_, char) => char.toUpperCase())
}

export const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>

  return (...args: Parameters<F>) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

export function isElementInViewport(element: HTMLElement | null): boolean {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function getCSSVariable(key: string) {
  const style = getComputedStyle(document.body)
  return style.getPropertyValue(`--${key}`)
}

export function formatDate(dateString: string) {
  const locale = i18n.locale.value || import.meta.env.VITE_DEFAULT_LOCALE
  const date = new Date(dateString)

  return new Intl.DateTimeFormat(locale, { month: 'long', year: 'numeric', day: 'numeric' }).format(
    date,
  )
}

export function formatDateToMonthYear(dateString: string): string {
  const locale = i18n.locale.value || import.meta.env.VITE_DEFAULT_LOCALE
  const date = new Date(dateString)
  return date.toLocaleString(locale, { month: 'long', year: 'numeric' })
}

export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const inIFrame = () => window !== window.parent

export const isEmptyObject = (obj: Record<string, any>): boolean => Object.keys(obj).length === 0

export const transformObjectEmptyValuesToNull = (obj: Record<string, any>): Record<string, any> => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.map((item) =>
          typeof item === 'object' && item !== null
            ? transformObjectEmptyValuesToNull(item)
            : item === ''
              ? null
              : item,
        )
      } else if (typeof value === 'object' && value !== null) {
        acc[key] = transformObjectEmptyValuesToNull(value)
      } else if (value === '') {
        acc[key] = null
      } else {
        acc[key] = value
      }
      return acc
    },
    {} as Record<string, any>,
  )
}

export const transformUrlToSecure = (url: string): string => {
  if (!url.match(/^https?:\/\//)) {
    return `https://${url}`
  }

  return url
}

export function includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
  return coll.includes(el as T)
}

export const parseImpactsArrayToObject = (impacts: Impact[]): Record<ImpactType, number> => {
  const map = new Map<ImpactType, number>()
  for (const type of IMPACT_TYPES) {
    map.set(type, 0)
  }
  for (const impact of impacts) {
    map.set(impact.type, new Decimal(map.get(impact.type) || 0).add(impact.amount).toNumber())
  }

  return Object.fromEntries(map.entries()) as Record<ImpactType, number>
}

export const getImageUrl = (url: string) => {
  const assets = import.meta.glob('/src/assets/images/**', { eager: true })
  if (assets[url]) {
    return (assets[url] as { default: string }).default
  }
}

export function hexToRgb(hex: string): string {
  hex = hex.replace(/^#/, '')

  const bigint = parseInt(hex, 16)

  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `${r}, ${g}, ${b}`
}

type Obj = { [key: string]: any }

export const updatedDiff = (obj1: Obj, obj2: Obj): Obj => {
  const updates: Obj = {}

  Object.keys(obj1).forEach((key) => {
    if (!obj1[key] && !Object.prototype.hasOwnProperty.call(obj2, key)) {
      return null
    } else if (!obj2 || !Object.prototype.hasOwnProperty.call(obj2, key)) {
      updates[key] = null
    } else if (obj2 && !isEqual(obj1[key], obj2[key])) {
      if (isObject(obj1[key]) && isObject(obj2[key]) && !isArray(obj1[key])) {
        updates[key] = updatedDiff(obj1[key], obj2[key])
      } else {
        updates[key] = obj2[key]
      }
    }
  })

  Object.keys(obj2).forEach((key) => {
    if (!obj1[key] && obj2[key]) {
      updates[key] = obj2[key]
    }
  })

  return updates
}

export const serializeParams = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams()

  Object.keys(params).forEach((key) => {
    const value = params[key]

    if (Array.isArray(value)) {
      searchParams.append(key, value.join(','))
    } else if (value !== undefined && value !== null) {
      searchParams.append(key, value.toString())
    }
  })

  return searchParams.toString()
}
