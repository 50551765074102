export const en = {
  trees: 'Trees',
  carbon: 'CO2',
  plastic: 'Plastic',
  kelp: 'Kelp',
  upload: {
    button: 'Upload your image here',
  },
  colorPicker: {
    color: 'Current: {color}',
  },
  validations: {
    required: 'Field is required.',
    minLength: 'Field must be more than {minLength} characters.',
    maxLength: 'Field must be less than {maxLength} characters.',
    maxValue: 'Field must be less than max value of {maxValue}.',
    url: 'Field must be a valid url.',
    email: 'Field must be a valid email address.',
  },
  confirmDialog: {
    ok: 'Confirm',
    cancel: 'Cancel',
  },
  NotFoundPage: {
    title:
      'Our web page seems to have taken an eco-adventure of its own and wandered off into the digital wilderness.',
    description:
      "But don't worry! Just like you we're committed to cleaning up our oceans and offsetting carbon emissions, we're also dedicated to helping you find what you're looking for.",
    button: 'Take me home',
  },
  PublicHeader: {
    links: {
      business: 'Business',
      personal: 'Personal',
      about: 'About',
    },
    buttons: {
      login: 'Login',
      register: 'Sign up',
    },
  },
  PublicFooter: {
    climate_hero: {
      title: 'Be a climate hero',
      cta: 'Start saving the planet',
    },
    copyright: 'Copyright © {currentYear} Greenspark. All rights reserved.',
    menus: {
      company: 'Company',
      contact: 'Contact us',
      legal: 'Legal',
    },
    links: {
      about: 'About us',
      projects: 'Projects',
      case_studies: 'Case studies',
      journal: 'Journal',
      book_demo: 'Book a demo',
      email: 'Email us',
      terms_and_conditions: 'Terms & conditions',
      privacy_policy: 'Privacy & Cookies',
    },
  },
  PublicHero: {
    description:
      'We partnered with Greenspark to increase our positive impact on the planet and its people. Check out our journey so far and join us in our mission!',
    description_company:
      'We joined Greenspark to ensure that {name} has a positive impact on our planet and its people. Check out our impact so far and join our journey!',
    description_personal:
      'I joined Greenspark to ensure that I maximise my positive impact on our planet and its people. Check out my impact so far and join my journey!',
    member_since: 'Member since',
    since: 'Creating impact since 2021',
    website: 'Our website',
  },
  PublicTopStatCardList: {
    title: 'Our impact generated so far',
    new_member: {
      title: 'New member!',
      description:
        'We <strong>just started</strong> our journey with Greenspark and no projects have been funded - yet!',
    },
    check_below: 'Check below to see some projects we’ll be supporting:',
  },
  PublicTopStatCard: {
    trees: 'tree planted | trees planted',
    plastic: 'plastic bottle rescued | plastic bottles rescued',
    carbon: 'tonne of CO2 offset | tonnes of CO2 offset',
    kelp: 'kelp planted',
    months_earth_positive: 'earth positive',
    months_amount: '{count} month | {count} months',
    tooltip: {
      transferredImpact: {
        title: '<strong>Transferred Impact:</strong> {amount} ',
        carbon:
          'tonne of CO2 has been transferred from a previous source. Check the public ledger to see the proof. | tonnes of CO2 have been transferred from a previous source. Check the public ledger to see the proof.',
        plastic:
          'plastic bottle has been transferred from a previous source. Check the public ledger to see the proof. | plastic bottles have been transferred from a previous source. Check the public ledger to see the proof.',
        trees:
          'tree has been transferred from a previous source. Check the public ledger to see the proof. | trees have been transferred from a previous source. Check the public ledger to see the proof.',
        kelp: 'kelp has been transferred from a previous source. Check the public ledger to see the proof. | kelp have been transferred from a previous source. Check the public ledger to see the proof.',
      },
      partnerImpact: {
        title: '<strong>Partner Impact:</strong> {amount} ',
        carbon:
          'tonne of CO2 has come from partnerships. | tonnes of CO2 have come from partnerships.',
        plastic:
          'plastic bottle has come from partnerships. | plastic bottles have come from partnerships.',
        trees: 'tree has come from partnerships. | trees have come from partnerships.',
        kelp: 'kelp has come from partnerships. | kelp have come from partnerships.',
      },
    },
  },
  PublicComparisonCardRow: {
    carbon: 'Our CO2 offsets are equivalent to',
    plastic: 'Our collected plastic is equivalent to',
    trees: 'Our planted trees are equivalent to',
    kelp: 'Our planted kelp is equivalent to',
  },
  PublicComparisonCard: {
    carbon: {
      flights: {
        desc: 'roundtrip flights (London > LA) worth of carbon saved.',
        link: 'On average one round trip creates 932.7 kg of emissions. This calculation varies by airline.',
      },
      distance: {
        desc: 'miles driven in an average car (2-axle 4-tire vehicles).',
        link: 'The average person drives 13,476 miles per year, emitting 5.43 tonnes of CO2.',
      },
      plantBased: {
        desc: 'years worth of carbon savings from plant- based living.',
        link: 'Living plant-based saves approximately 0.8 tonnes of CO2 per year.',
      },
    },
    plastic: {
      straws: {
        desc: 'straws worth of plastic removed from landfill.',
        link: 'The average straw weighs 0.5g',
      },
      coffeeCups: {
        desc: 'disposable coffee cups worth of plastic saved from landfill.',
        link: 'The average plastic content of a coffee cup is 4g.',
      },
      bathtubs: {
        desc: 'bathtubs of plastic bottles rescued from the sea.',
        link: 'The largest size Victoria Plumb bathtub has a capacity of 260L, therefore it would take approx. 520x500ml bottles to fill one.',
      },
    },
    trees: {
      areaPlanted: {
        desc: 'of forest area planted.',
        link: 'Our trees are planted at a density of 2,500 trees / hectare.',
      },
      carbonSequestered: {
        desc: 'of potential CO2 sequestered per year.',
        link: 'On average a hectare of fully grown trees will sequester 23t of CO2 per year.',
      },
      footballPitches: {
        desc: 'football pitches worth of trees planted (0.71 hectares).',
        link: 'The average football pitch is 0.71 hectares and our trees are planted at a density of 2,500 trees / hectare.',
      },
    },
    kelp: {
      babyWhales: {
        desc: 'baby humpback whale’s weight of kelp planted.',
        link: 'A baby humpback whale weights 2267 kg while a kelp weights 0.0278kg on average.',
      },
      iceHockeyRinks: {
        desc: 'hockey rinks full of seaweed.',
        link: 'A regular hockey rink in North America is 1580 square metres. Dense beds of kelp have 100 plants per 1 square metres.',
      },
      carbonSequestered: {
        desc: 'of potential CO2 sequestered per year.',
        link: 'On average a hectare of fully grown kelp will sequester 14t of CO2 per year.',
      },
    },
  },
  PublicLedger: {
    title: 'Public Ledger',
    description:
      "Every quarter we provide all of the impact evidence's funding has contributed to. Because certifications and transparency matter.",
    view: 'View',
    transferredImpact: {
      label: 'Transferred Impact',
      title:
        '{name} created impact before they joined Greenspark and has added it to their account from another source. See below for verified confirmation documents.',
    },
    all: 'View all public ledgers',
    load_more: 'Load more',
  },
  PublicCTA: {
    title: "We invite you to join {name}'s mission and be a Climate Hero!",
    list: {
      integrate: 'Easily integrate to your business.',
      impact: 'Turn environmental impact into a catalyst for success.',
      plans: 'Personal and business plans to support climate projects daily.',
      goals: 'Create the perfect plan for your goals.',
    },
    action: 'Start saving the planet',
  },
  PublicJoinGreenspark: {
    title: 'Join the Greenspark revolution!',
    button: 'Sign up',
  },
  PublicGoalsAchievements: {
    title: "{name}'s Sustainability Journey",
    description:
      'We partnered with Greenspark to increase our positive impact on the planet and it’s people. This is our journey so far and our goals for the future.',
    greenspark: {
      title: 'Our sustainability journey',
      description:
        "We've teamed up with partners to enhance our collective positive impact on the planet and its people. Join us as we share our ongoing journey and outline our future goals.",
    },
    goals: {
      title: 'Our Goals',
    },
    achievements: {
      title: 'Our Achievements',
    },
  },
  CommunityPage: {
    title: 'Greenspark Community Impact',
    description:
      'We can only do what we do because of our amazing members. Check out our overall community impact and supported projects!',
    goals: {
      first:
        'Grow the Greenspark portfolio of trusted impact partnerships to include more types in more locations worldwide with 3 new impact types in 2024.',
      second:
        'Continue to improve transparency of projects through introduction of new technology, greater visibility and tracking to ensure accuracy and trust.',
      third: 'Hit 3 million trees planted by the end of 2024.',
    },
    achievements: {
      first: 'Grown our community of changemakers to more than 600 businesses.',
      second: 'Positively impacted dozens of communities and hundreds of lives worldwide.',
      third: 'Proven that environmental action can also drive business success.',
    },
  },
  ComparisonCardPublic: {
    billion: 'bn',
    million: 'mn',
  },
  EditorHeader: {
    buttons: {
      back: 'Back',
      backToEditor: 'Back to editor',
      preview: 'Preview',
      saveAndPublish: {
        label: 'Save and publish',
        success: 'Successfully published the new changes!',
        error: 'Error occured while saving changes!',
      },
    },
    unsavedChanges: {
      title: 'You have unsaved changes.',
      description:
        "It looks like you've made some edits to your landing page, but haven't saved them yet. Are you sure you want to leave?",
      cancel: 'Cancel',
      ok: 'Leave page',
    },
  },
  EditorSidebar: {
    welcome: {
      title: 'Personalise your public page',
      subtitle:
        'Welcome to your Sustainability Public Profile setup! With this page, you can showcase your impact and commitment to sustainability to everyone.',
    },
    default: 'Default',
  },
  EditorSettings: {
    upgradePlan: {
      growth: 'Upgrade to Growth Plan and unlock',
      premium: 'Upgrade to Premium Plan and unlock',
    },
  },
  EditorHeroSettings: {
    title: 'Header Setup',
    social: {
      title: 'Link your social media accounts',
      description: 'Link your social media accounts to share your progress.',
    },
    content: {
      title: 'Personalised message',
      description: 'Craft a message that represents your sustainability journey.',
      fields: {
        pageTitle: {
          label: 'Page title',
          placeholder: 'Our sustainability journey',
        },
        pageDescription: {
          label: 'Page description',
          placeholder: 'Message that represents your sustainability journey.',
        },
        memberSince: {
          label: 'Show membership date.',
        },
      },
    },
    logo: {
      title: 'Upload your logo',
      description:
        'We accept .jpeg, .png and .webp files below 2.0MB. If you already added your logo during your onboarding, it will be displayed here.',
      fields: {
        upload: 'Upload your image here',
        removeLogo: {
          label: 'Show company name and logo',
          placeholder: 'Select this option to show your logo and company name in your page header.',
        },
      },
    },
    style: {
      title: 'Choose a header style',
      description: 'Select the placement for the informations you will display on your header.',
      center: 'Centralised',
      sideBySide: 'Side by side',
      alignLeft: 'Aligned left',
    },
    image: {
      title: 'Include an image on the header',
      withImage: 'With image',
      withoutImage: 'Without image',
      upload: {
        title: 'Upload your image',
        description: 'We accept .jpeg, .png and .webp files below 5.0MB',
      },
      overlayColour: {
        title: 'Choose an overlay colour',
        description:
          'In order to create a good contrast with the text colour, use a colour that is darker/lighter than the background image.',
      },
      textColor: {
        title: 'Choose a text colour',
      },
    },
  },
  EditorImpactSettings: {
    title: 'Impact Section',
    color: {
      title: 'Choose colours for impact sections',
      backgroundColor: 'Background colour',
      textColor: 'Text colour',
      trees: 'Trees planted',
      kelp: 'Kelp planted',
      carbon: 'CO2 Offset',
      plastic: 'Plastic Collected',
    },
    displayed: {
      title: 'Choose information displayed',
      trees: 'Trees planted',
      kelp: 'Kelp planted',
      plastic: 'Plastic bottles rescued',
      carbon: 'CO2 offset',
      monthsEarthPositive: 'Months earth positive',
      hideZeroValues: 'Hide zero impact types',
      errorMessage: 'At least one item must be selected.',
    },
    style: {
      title: 'Choose an icon style for impact section',
      description: 'Choose from the three available icon sets that resonates with your brand.',
      simple: 'Simplified',
      flat: 'Flat Icons',
      isometric: 'Isometric',
    },
  },
  EditorGoalsAchievementsSettings: {
    title: 'Goals and Achievements',
    goals: {
      title: 'Set Goals',
      description: 'Write down 2-5 impact statements that reflect your future objectives.',
    },
    achievements: {
      title: 'Share Achievements',
      description:
        "Document 2-5 noteworthy accomplishments you've already achieved in your sustainability journey.",
    },
  },
  EditorBackgroundSettings: {
    title: 'Background Settings',
    theme: {
      title: 'Choose a background color',
      light: 'Light theme',
      dark: 'Dark theme',
      custom: {
        label: 'Set your own colour',
        description:
          "Select a personalised colour theme that aligns with your profile's theme and branding.",
        backgroundColor: 'Background colour',
        textColor: 'Text colour',
      },
    },
    icon: {
      title: 'Background imagery',
      description:
        'Optionally, choose an option below or upload an image that represents your journey.',
      leaves: 'Leaves',
      circles: 'Circles',
      clouds: 'Clouds',
      paws: 'Paws',
    },
  },
}
