import { getCurrentUser } from 'vuefire'
import axios, { type AxiosResponse, isCancel } from 'axios'
import * as Sentry from '@sentry/vue'

import { getCookie, clearCookie, AUTH_TOKEN_COOKIE } from '@/helpers/cookie'
import type {
  IProject,
  IProjectRequestParams,
  IPublicAccount,
  IReceipt,
  IEditorSettings,
  IPublicPageEditor,
  IAccount,
  ISubscriptionItem,
  ICommunityAccount,
  TransactionPurchase,
} from '@/api/interfaces'
import { serializeParams } from '@/helpers/utils'

const getApiUrl = (api: 'dashboard' | 'openApi') => {
  if (window.location.hostname === 'localhost') {
    switch (api) {
      case 'dashboard':
        return import.meta.env.VITE_DASHBOARD_API_URL
      case 'openApi':
        return import.meta.env.VITE_OPEN_API_URL
    }
  } else {
    return import.meta.env.VITE_APP_URL
  }
}

const api = axios.create({
  baseURL: getApiUrl('dashboard'),
  timeout: 100000,
})

const openApi = axios.create({
  baseURL: getApiUrl('openApi'),
  timeout: 100000,
})

api.interceptors.request.use(async (config) => {
  const token = getCookie(AUTH_TOKEN_COOKIE)

  if (token) {
    config.headers.AuthToken = token
  }

  return config
})

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const currentUser = await getCurrentUser()

    if (isCancel(error)) {
      return
    }

    switch (error?.response?.status) {
      case 401:
        clearCookie(AUTH_TOKEN_COOKIE)
        window.location.href = `${import.meta.env.VITE_FRONTEND_URL}/login`
        break
      case 404:
        window.location.pathname = `/not-found-page`
        break
      default:
        Sentry.captureException(error, {
          user: { id: currentUser?.uid },
          extra: { ...JSON.parse(JSON.stringify(error)) },
        })
        break
    }

    return Promise.reject(error)
  },
)

export const getPublicAccount = async (id: string): Promise<AxiosResponse<IPublicAccount>> => {
  return openApi.get<IPublicAccount>(`v1/accounts/public/${id}`)
}

export const getCommunityAccount = async (): Promise<AxiosResponse<ICommunityAccount>> => {
  return openApi.get<ICommunityAccount>(`v1/accounts/community`)
}

export const getProjects = async (
  params?: IProjectRequestParams,
): Promise<AxiosResponse<IProject[]>> => {
  return openApi.get<IProject[]>('v1/projects', {
    params,
    paramsSerializer: serializeParams,
  })
}

export const getReceipts = async (): Promise<AxiosResponse<IReceipt[]>> => {
  return openApi.get<IReceipt[]>('/receipts')
}

export const getPurchases = async (): Promise<AxiosResponse<TransactionPurchase[]>> => {
  return openApi.get<TransactionPurchase[]>('v1/impacts/purchases')
}

export const getEditorSettings = async (id: string): Promise<AxiosResponse<IPublicPageEditor>> => {
  return openApi.get(`v1/public-page/editor-settings/${id}`)
}

export const updateEditorSettings = async (
  payload: Partial<IEditorSettings>,
): Promise<AxiosResponse<IEditorSettings>> => {
  return api.patch('v1/public-page/editor-settings', payload)
}

export const getAccount = async (): Promise<AxiosResponse<IAccount>> => {
  return api.get<IAccount>('account')
}

export const getSubscriptionItems = async (): Promise<AxiosResponse<ISubscriptionItem[]>> => {
  return api.get<ISubscriptionItem[]>('subscription-items')
}
